import { useEffect, useState } from "react";
import { Card, Button, Row, Col, Accordion, Modal } from "react-bootstrap";

function App() {
  //field input
  const [name, setName] = useState("");
  //gerichte holen
  const [loading, setLoading] = useState();
  const [gerichte, setGerichte] = useState();
  //fürs modal
  const [confToday, setConfToday] = useState(false);
  const [gericht, setGericht] = useState();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSend = async () => {
    setLoading(true);
    try {
      const request = await fetch(
        //`http://localhost/essen/rest-api/request.php`,
        `https://essen.mogugo.de/rest-api/request.php`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify({
            gericht: name,
          }),
        }
      );
    } catch (e) {
    } finally {
      setName("");
      loadGerichte();
      setLoading(false);
    }
  };

  const loadGerichte = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        //`http://localhost/essen/rest-api/request.php?gerichte=1`
        `https://essen.mogugo.de/rest-api/request.php?gerichte=1`
      );
      setGerichte(await response.json());
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleCookToday = (gericht) => {
    setGericht(gericht);
    setConfToday(true);
  };

  const handleUpdateDate = async () => {
    try {
      let numWeeks = 8;
      let now = new Date();
      now.setDate(now.getDate() + numWeeks * 7);
      const request = await fetch(
        //`http://localhost/essen/rest-api/request.php`,
        `https://essen.mogugo.de/rest-api/request.php`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify({
            update: gericht,
            new_date: now,
            today: new Date(),
          }),
        }
      );
    } catch (e) {
      console.error(e);
    } finally {
      loadGerichte();
      setConfToday(false);
    }
  };

  const addLeadingZeros = (n) => {
    if (n <= 9) {
      return "0" + n;
    }
    return n;
  };

  useEffect(() => {
    loadGerichte();
  }, []);

  return (
    <div
      style={{ height: "100vh" }}
      className="container-fluid bg-dark bg-gradient text-white justify-content-between"
    >
      <div className="pt-3 h1 text-center">Essen</div>
      <Card
        bg="dark ps-3 pt-2 pb-2"
        style={{ marginRight: "10%", marginLeft: "10%" }}
      >
        neues Gericht hinzufügen
        <div className="d-flex align-items-center">
          <input
            className="mt-2 mb-2 rounded-pill ps-3"
            type={"text"}
            value={name}
            onChange={handleNameChange}
            style={{ backgroundColor: "darkgray", width: "75%" }}
          />
          <Button
            disabled={loading || name.length < 3}
            onClick={handleSend}
            className="ms-3 rounded-pill"
            size="sm"
          >
            {loading === true ? "..." : "ok"}
          </Button>
        </div>
      </Card>
      <Card bg="secondary" className="mt-3">
        <Row className="border-bottom">
          <Col xs={7}>Gericht</Col>
          <Col xs={3}>zuletzt</Col>
          <Col xs={2}>Rez.</Col>
        </Row>
        {loading === false &&
          gerichte.map((gericht, index) => {
            let date = new Date(gericht.letztes_mal);
            let formattedDate =
              addLeadingZeros(date.getDate()) +
              "." +
              addLeadingZeros(date.getMonth() + 1) +
              "." +
              date.getFullYear().toString().substr(-2);

            let next_date = new Date(gericht.naechste_mal);
            let next_date_form =
              addLeadingZeros(next_date.getDate()) +
              "." +
              addLeadingZeros(next_date.getMonth() + 1) +
              "." +
              next_date.getFullYear().toString().substr(-2);
            return (
              <Row className="border-bottom align-items-start" key={index}>
                <Col xs={7}>
                  <Accordion flush>
                    <Accordion.Item
                      style={{ backgroundColor: "initial" }}
                      eventKey={index}
                    >
                      <Accordion.Button
                        style={{ backgroundColor: "initial" }}
                        className="p-1"
                      >
                        {gericht.name}
                      </Accordion.Button>
                      <Accordion.Body className="pt-2 p-1">
                        <div className="d-flex justify-content-between">
                          <div>früh. wieder:</div>
                          <div>{next_date_form}</div>
                        </div>
                        <Button
                          onClick={() => handleCookToday(gericht)}
                          variant="outline-dark"
                          size="sm"
                        >
                          heute zub.
                        </Button>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
                <Col className="p-1" xs={3}>
                  {formattedDate !== "NaN.NaN.aN" && formattedDate}
                </Col>
                <Col className="p-1" xs={2}></Col>
              </Row>
            );
          })}
      </Card>
      {gericht && (
        <Modal centered show={confToday} onHide={() => setConfToday(false)}>
          <Modal.Header closeButton>{gericht.name}</Modal.Header>
          <Modal.Body>hast du das heute gekocht?</Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleUpdateDate}
              variant="outline-success"
              size="sm"
            >
              ja
            </Button>
            <Button
              onClick={() => setConfToday(false)}
              variant="outline-danger"
              size="sm"
            >
              nein
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default App;
